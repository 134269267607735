import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom"
import ProductsHome from "./pages/ProductsHome/ProductsHome";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import "./app.scss";
import "./fonts/Prompt-Regular.ttf";
import Burgers from "./pages/Burgers/Burgers";
import Pizza from "./pages/Pizza/Pizza";
import Sides from "./pages/Sides/Sides";
import Drinks from "./pages/Drinks/Drinks";
import MealDeals from "./pages/MealDeals/MealDeals";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";

const Layout = () =>{
  return(
    <div className="app">
      <Navbar/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path:"/",
    element:<Layout/>,
    children: [
      {
        path:"/",
        element:<Home/>
      },
      {
        path:"/products/home",
        element:<ProductsHome/>
      },
      {
        path:"/products/1",
        element:<Burgers/>
      },
      {
        path:"/products/2",
        element:<Pizza/>
      },
      {
        path:"/products/3",
        element:<Sides/>
      },
      {
        path:"/products/4",
        element:<Drinks/>
      },
      {
        path:"/products/5",
        element:<MealDeals/>
      },
      {
        path:"/login",
        element:<Login/>
      },
      {
        path:"/register",
        element:<Register/>
      },
    ]
  },

])

function App() {
  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
