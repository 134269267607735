import React from 'react'
import { Link } from 'react-router-dom'
import "./CardFeatured.scss"

const CardFeatured = ({item}) => {
  return (
        <div className="cardFeatured">
            <Link className='link' to={`/products/1`}>
            <div className="image">
                <img src={item.img} alt="" />
            </div>
            </Link>
            <div className="bottom">
                <div className="price">
                    <h2>£{item.price}</h2>
                </div>
                <div className="title">
                    <h3>{item.title}</h3>
                </div>
                <div className="desc">
                    <h4>{item.desc}</h4>
                </div>
                <div className="button">
                    <Link className='link' to={`/products/1`}>
                    <button>ORDER NOW</button>
                    </Link>
                </div>
            </div>
            
        </div>
  )
}

export default CardFeatured