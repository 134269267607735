import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    products: []
}

export const basketSlice = createSlice ({
    name: 'basket',
    initialState,
    reducers: {
        addtoBasket: (state,action) =>{
            const item = state.products.find(item => item.id === action.payload.id)

                if(item){
                    item.quantity += action.payload.quantity;
                } else {
                    state.products.push(action.payload);
                }
        },
        removeItem: (state, action) => {
            state.products = state.products.filter(item => item.id !== action.payload)
        },
        resetBasket: (state, action) => {
            state.products = []
        }
    }
});

export const {addtoBasket, removeItem, resetBasket} = basketSlice.actions;

export default basketSlice.reducer;