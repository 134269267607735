import "./Profile.scss"



const Profile = ({username}) => {




    return (
        <div className="profile">
                <div className="welcome">
                    <span>WELCOME {username}</span>
                    <br></br>
                    <span>YOU'RE READY TO START ORDERING!</span>
                </div>
        </div>
    )
};

export default Profile