import React, {useState} from 'react'
import "./ProductCard.scss"
import { useDispatch } from 'react-redux'
import { addtoBasket } from '../../redux/basketReducer';
import useFetch from '../../hooks/useFetch';

const ProductCard = ({item}) => {

    
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const {data, loading} = useFetch(`/products/find/${item._id}`);

  return (
    <div className="productCard">
        <div className="image">
            <img src={item.img} alt="" />
        </div>
        <div className="info">
            <div className="title">
                {item.title}
            </div>
            <div className="desc">
                {item.desc}
            </div>
            <div className="price">
                £{item.price.toFixed(2)}
            </div>
            <div className="quantity">
                    <button onClick={()=> setQuantity(prev=>prev ===1 ? 1 : prev -1)}>-</button>
                        {quantity}
                    <button onClick={()=> setQuantity(prev=>prev+1)}>+</button>
                </div>
            <div className="addToBasket">
                <button
                onClick={() => dispatch(addtoBasket({
                    id: data._id,
                    title: data.title,
                    desc: data.desc,
                    price: data.price,
                    img: data.img,
                    quantity
                }))}
                
                >ADD TO BASKET</button>
            </div>
        </div>
        
    </div>
  )
}

export default ProductCard