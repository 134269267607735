import React from 'react'
import FeaturedProducts from '../../components/FeaturedProducts/FeaturedProducts'

const Home = () => {
  return (
    <div className="home">
      
      <FeaturedProducts/>
    </div>
  )
}

export default Home