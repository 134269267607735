import React, {useState} from 'react'
import "./Login.scss"
import {Link} from 'react-router-dom';
import axios from 'axios';
import Profile from '../../components/Profile/Profile';


const Login = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) =>{
    e.preventDefault();

    try{
        const res = await axios.post(process.env.REACT_APP_API_URL+ "/auth/login", {
            username: `${username}`,
            password: `${password}`
       })
       sessionStorage.setItem("authToken", res.data.accessToken);
       sessionStorage.setItem("userId", res.data._id);
       sessionStorage.setItem("username", res.data.username);
       window.location.reload();
       
    }catch(err){
        console.log(err);
    }
   

}

  return (
    <div className="login">
      {sessionStorage.getItem("authToken") ? <Profile username={sessionStorage.getItem("username")} className='profile'/> :
        <div className="left">
            <form method='post' className='inputForm' onSubmit={handleSubmit}>
            <input 
                type="text" 
                id="username" 
                name="username"
                value={username} 
                placeholder="Username"
                onChange={(e) => {
                    setUsername(e.target.value)
                }}
            />
            <input 
                type="password"
                id="password"
                name="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
            />
            <button type="submit">SIGN IN</button>
            </form>
            <div className="or">
              <span>OR</span>
            </div>
            <div className="createAccount">
              <Link className='link' to='/register'><button>CREATE NEW ACCOUNT</button></Link>
            </div>
        </div>}
        <div className="right">
            <img src="https://images.pexels.com/photos/7142962/pexels-photo-7142962.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
        </div>
    </div>
  )
}

export default Login