import React from 'react'
import "./Basket.scss"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutline";
import {useDispatch, useSelector} from "react-redux";
import { removeItem, resetBasket } from '../../redux/basketReducer';

const Basket = () => {

    const dispatch = useDispatch();
    const products = useSelector(state => state.cart.products);

    const totalPrice = () => {
        let total = 0;
        products.forEach((item) => (total += item.quantity * item.price));
        return total.toFixed(2);
       }

    const createCheckout = () =>{
        fetch(process.env.REACT_APP_API_URL + '/checkout/create-checkout', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
                items: [
                    products?.map(item => ({
                        id: item.id,
                        quantity: item.quantity
                    }))
                ]
            })
        }).then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        }).then (({url}) => {
            console.log(url);
            // window.location = url
        }).catch(e => {
            console.error(e.error)
        })
    }
    
  return (
    <div className="basket">
        <h1>Products in your Basket</h1>
        {products?.map(item=>(
        <div className="item" key={item.id}>
            <img src={item.img} alt="" />
            <div className="details">
                <h1>{item.title}</h1>
                <p>{item.desc?.substring(0,100)}</p>
                <div className="price">{item.quantity} x £{item.price}</div>
                <DeleteOutlinedIcon className='delete' onClick={() => dispatch(removeItem(item.id))}/>
            </div>
        </div>
        ))}
        <div className="total">
            <span>SUBTOTAL</span>
            <span>£{totalPrice()}</span>
        </div>
        <button onClick={createCheckout}>CHECKOUT</button>
        <span className='reset' onClick={()=> dispatch(resetBasket())}>RESET BASKET</span>
        
    </div>
  )
}

export default Basket