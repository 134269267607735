import React from 'react'
import { Link } from 'react-router-dom'
import "./MenuCard.scss"

const MenuCard = ({item}) => {
  return (
    <Link className='link' to={`/products/${item._id}`}>
        <div className="menuCard">
            <div className="image">
                <img src={item.img} alt="" />
            </div>
            <div className="title">
                {item.title}
            </div>
        </div>
    </Link>
  )
}

export default MenuCard