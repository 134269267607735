import React, {useState} from 'react'
import "./Navbar.scss"
import { Link } from 'react-router-dom'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Basket from '../Basket/Basket';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';

const Navbar = () => {

    const products = useSelector(state => state.cart.products);

    const [open, setOpen] = useState(false);
    
    const logOut = () =>{
        sessionStorage.clear();
        window.location.reload();
    }

  return (
    <div className='navbar'>
        <div className="wrapper">
            <div className="left">
                {
                sessionStorage.getItem("authToken") ?
                <div className="loggedIn">
                    <span className='accountName'><PersonIcon className='icon'/>{sessionStorage.getItem("username")}</span>
                <span className='logout' onClick={logOut}>Log Out</span><LogoutIcon className='icon'/>
                </div>
                :
                <>
                <Link className='link' to='/register'><PersonAddIcon className='icon'/>REGISTER</Link>
                <span>|</span>
                <Link className='link' to='/login'><PersonIcon className='icon'/>LOGIN</Link>
                </>
                }         
            </div>
            <div className="center">
                <img src="/img/fastFoodIcon.png" alt="" />
                <Link className='link' to='/'><span>HUGH'S FOODS</span></Link>
            </div>
            <div className="right">
                <div className="item">
                    <Link className='link' to='/products/home'>MENU</Link>
                </div>
                <div className="cart" onClick={() => setOpen(!open)}>
                    <ShoppingBasketIcon/>
                    <span>{products.length}</span>
                </div>
            </div>
        </div>
        {open && <Basket/>}
    </div>
  )
}

export default Navbar