import React from 'react'
import "./FeaturedProducts.scss"
import CardFeatured from '../CardFeatured/CardFeatured'
import {Link} from "react-router-dom"
import useFetch from '../../hooks/useFetch'

const FeaturedProducts = () => {

    const {data, loading, error} = useFetch('/products?category=burger')

  return (
    <div className="featuredProducts">
        <div className="top">
            <div className="left">
                <h1>ANYTIME, ANYWHERE, ALWAYS TASTY</h1>
            </div>
            <div className="right">
                <div className="col">
                    <div className="row">
                        <img src="https://images.pexels.com/photos/7142962/pexels-photo-7142962.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <img src="https://images.pexels.com/photos/1025804/pexels-photo-1025804.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <img src="https://images.pexels.com/photos/4348786/pexels-photo-4348786.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
                    </div>
                    <div className="row">
                        <img src="https://images.pexels.com/photos/1059040/pexels-photo-1059040.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div className="center">
            <span>GRAB A BURGER!</span>
            <Link className='link' to='/products/home'><button>ORDER NOW</button></Link>
        </div>
        <div className="bottom">
            {error ? "Something went wrong!" : (loading ? "loading..." : data.map(item =>(
                <CardFeatured item={item} key={item._id}/>
            )))}
        </div>
    </div>
  )
}

export default FeaturedProducts