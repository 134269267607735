import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import "./Footer.scss"
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
        <div className="top">
                <div className="item">
                    <h1>Categories</h1>
                    <Link className='link' to='/products/1'><span>Burgers</span></Link>
                    <Link className='link' to='/products/2'><span>Pizza</span></Link>
                    <Link className='link' to='/products/3'><span>Sides</span></Link>
                    <Link className='link' to='/products/4'><span>Drinks</span></Link>
                    <Link className='link' to='/products/5'><span>Deals</span></Link>
                </div>
                <div className="item">
                    <h1>Links</h1>
                    <span>FAQ</span>
                    <span>Pages</span>
                    <span>Stores</span>
                    <span>Cookies</span>
                </div>
                <div className="item">
                    <h1>About</h1>
                    <span>Don't judge each day by the harvest you reap but by the seeds 
                        that you plant.
                         -Robert Louis Stevenson<br/>
                         The future belongs to those who believe in the beauty of their 
                         dreams. 
                         -Eleanor Roosevelt<br/>
                         Do not go where the path may lead, go instead where there is 
                         no path and leave a trail. 
                         -Ralph Waldo Emerson<br/></span>
                </div>
                <div className="item">
                <h1>FOLLOW US</h1>
                    <span><FacebookIcon className='icon'/> FACEBOOK</span>
                    <span><InstagramIcon className='icon'/>INSTAGRAM</span>
                    <span><TwitterIcon className='icon'/>TWITTER</span>
                    <span><YouTubeIcon className='icon'/>YOUTUBE</span> 
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <span className='logo'>HUGH'S FOODS</span>
                    <span className='copyright'>© Copyright 2023. All Rights Reserved</span>
                </div>
                <div className="right">
                    <img src='/img/payment.png' alt='' />
                </div>
            </div>
    </div>
  )
}

export default Footer