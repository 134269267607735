import React from 'react'
import MenuCard from '../../components/MenuCard/MenuCard'
import "./ProductsHome.scss"

const ProductsHome = () => {
    const data = [
        {
            _id:1,
            title: "Burgers",
            img: "https://media.istockphoto.com/id/617759204/photo/steakhouse-double-bacon-cheeseburger.jpg?s=612x612&w=0&k=20&c=QRaHtrxJsmNKOlOTkvxyE-o-3kM-Me1zU320yPT8ycI="
        },
        {
            _id:2,
            title: "Pizza",
            img: "https://media.istockphoto.com/id/1349560847/photo/sausage-and-vegetable-pizza-on-dark-background.jpg?s=612x612&w=0&k=20&c=VjDdBc_WrHh9dqI8jCSEoI1jeWQDVSdXOJJ5LijQoRA="
        },
        {
            _id:3,
            title: "Sides",
            img: "https://media.istockphoto.com/id/1280158821/photo/diverse-keto-dishes.jpg?s=612x612&w=0&k=20&c=V0YSYORJ5rwklY3adnK5K6XU3nhup1NIT_wq8BizJx8="
        },
        {
            _id:4,
            title: "Drinks",
            img: "https://media.istockphoto.com/id/1282546436/photo/refreshing-cold-dark-cola-soft-drink.jpg?s=612x612&w=0&k=20&c=74q9I9g6aeGox_qiDQ_GqQtUzOTvOvkCVsrb8HnRUtw="
        },
        {
            _id:5,
            title: "Meal Deals",
            img: "https://media.istockphoto.com/id/1473452859/photo/tasty-cheeseburger-glass-of-cola-and-french-fries-on-wooden-tray-close-up.jpg?s=612x612&w=0&k=20&c=QLq7mv50xRL-LSfLYgUwNHsHm8Ri5nWrIdNwQL0qCqc="
        },
    ]

  return (
    <div className="productsHome">
      <div className="ourMenu">
        <span>Our Menu</span>
      </div>
      <div className="categories">        
      {data.map(item =>(
                <MenuCard item={item} key={item._id}/>
            ))}
      </div>
    </div>
  )
}

export default ProductsHome