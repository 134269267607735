import React from 'react'
import "./MealDeals.scss"
import ProductCard from '../../components/ProductCard/ProductCard'
import { Link } from 'react-router-dom'

const MealDeals = () => {

    const data = [
        {
            title: "Tripple Stack",
            img: "https://media.istockphoto.com/id/1154731889/photo/cheeseburger-with-french-fries-and-cola.jpg?s=612x612&w=0&k=20&c=3-71Ia2cFSaFPCklDjH7jL77cthnI3tWoHq55ZqHZ_c=",
            price: 9,
            desc: "Three beef patties with cheese, lettuce, tomatoes, onion, bacon and our special sauce!",
            _id: 1
        },
        {
            title: "Classic Burger",
            img: "https://media.istockphoto.com/id/1174879737/photo/burger.jpg?s=612x612&w=0&k=20&c=04WLXOQOV07L-ewpNKDllfZLljy4o4D_PvMLu4Hz8Ic=",
            price: 6,
            desc: "Our grass fed beef with cheese, lettuce, tomatoes, onions, pickles and our special sauce!",
            _id: 2
        },
        {
            title: "Double Trouble",
            img: "https://media.istockphoto.com/id/612507780/photo/delicious-grilled-burger.jpg?s=612x612&w=0&k=20&c=UuhSNtd4uyGgCQjIQ8Xl0imaer6FGqPMf_NUUpEVuFo=",
            price: 8,
            desc: "Two of our grass fed beef patties with cheese, lettuce, tomatoes, onion, pickles and our special sauce!",
            _id: 3
        },
        {
            title: "Chicken fillet burger",
            img: "https://media.istockphoto.com/id/521207406/photo/southern-country-fried-chicken-sandwich.jpg?s=612x612&w=0&k=20&c=XfttLSxEO2YAjop4Gy0CIb1L5N_OK1tTKxmkiPr3QD8=",
            price: 7.50,
            desc: "Classic Chicken fillet with pickles, cheese and our homemade coleslaw!",
            _id: 4
        },
    ];

  return (
    <div className="mealDeals">
        <div className="title">
            <span>Burgers</span>
        </div>
        <div className="back">
            <Link className='link' to='/products/home'><button>BACK TO MENU</button></Link>
        </div>
        <div className="list">
        {data.map(item =>(
                <ProductCard item={item} key={item._id}/>
            ))}
        </div>
    </div>
  )
}

export default MealDeals