import React from 'react'
import "./Burgers.scss"
import ProductCard from '../../components/ProductCard/ProductCard'
import {Link} from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

const Burgers = () => {

    const {data, loading, error} = useFetch('/products?category=burger')

  return (
    <div className="burgers">
        <div className="title">
            <span>Burgers</span>           
        </div>
        <div className="back">
            <Link className='link' to='/products/home'><button>BACK TO MENU</button></Link>
            </div>
        <div className="list">
        {error ? "Something went wrong!" : (loading ? "loading..." :data.map(item =>(
                <ProductCard item={item} key={item._id}/>
            )))}
        </div>
    </div>
  )
}

export default Burgers